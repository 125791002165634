<template>
    <client-page>
        <page-section titAlign="text-center" class="page-section--first page-section--last">
            <tit-wrap-dot title="비밀번호 찾기" alignCenter>
                <template #txtAdd>
                    회원가입 시 등록한 휴대폰 번호를 입력해주세요. <br />
                    등록된 휴대폰 번호로 임시 비밀번호를 전달드립니다.
                </template>
            </tit-wrap-dot>

            <div class="mw-580px mx-auto">
                <v-row class="row--sm ma-md-n6px">
                    <v-col cols="12" class="pa-md-6px">
                        <v-text-field v-model="username" v-bind="{ ...attrs_input, loading }" :dense="false" placeholder="아이디를 입력하세요" />
                    </v-col>
                    <v-col cols="12" class="pa-md-6px">
                        <v-row class="row--xs">
                            <v-col>
                                <v-text-field v-model="phone" v-bind="{ ...attrs_input, loading }" :dense="false" placeholder="연락처 (‘-’를 제외하고 입력하세요.)" />
                            </v-col>
                            <v-col cols="auto">
                                <v-btn @click="certify" color="grey" x-large class="w-100 min-w-sm-100px">인증요청</v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" class="pa-md-6px">
                        <v-text-field v-model="otp" v-bind="{ ...attrs_input, loading }" :dense="false" type="number" placeholder="인증번호를 입력하세요." />
                    </v-col>
                </v-row>

                <div class="pt-16px pt-md-20px">
                    <v-row class="row--sm ma-md-n6px">
                        <v-col cols="12" class="pa-md-6px">
                            <v-btn v-bind="{ ...btn_primary, loading }" block class="v-size--xx-large" @click="recovery">비밀번호 찾기</v-btn>
                        </v-col>
                        <v-col cols="12" class="pa-md-6px">
                            <div class="divider-group text-center">
                                <ul class="grey--text">
                                    <li>
                                        <router-link to="/login">로그인</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/login/find-id/">아이디 찾기</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/join">회원가입</router-link>
                                    </li>
                                </ul>
                            </div>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { btn_primary, attrs_input } from "@/assets/variables";

import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import TitWrapDot from "@/components/client/dumb/tit-wrap-dot.vue";
import VPhoneField from "@/components/plugins/vuetify/v-phone-field.vue";

export default {
    components: {
        ClientPage,
        PageSection,
        TitWrapDot,
        VPhoneField,
    },
    data: () => {
        return {
            username: null,
            phone: null,
            otp: null,

            loading: false,
            btn_primary,
            attrs_input,
        };
    },
    methods: {
        validate(recovery = false) {
            try {
                if (!this.username) throw new Error("아이디를 입력해주세요");
                if (!this.phone) throw new Error("휴대전화번호을 입력해주세요");
                if (recovery && !this.otp) throw new Error("인증번호를 입력해주세요");

                return true;
            } catch (error) {
                alert(error.message);
            }
            return false;
        },

        async certify() {
            if (this.validate()) {
                let { phone, username } = this;
                await api.v1.users.certify({
                    type: "phone",
                    kind: "password",
                    phone,
                    username,
                });

                alert("인증번호가 전송되었습니다");
            }
        },

        async recovery() {
            if (this.validate(true)) {
                let { otp, phone, username } = this;
                await api.v1.users.recovery({
                    type: "phone",
                    kind: "password",
                    otp,
                    phone,
                    username,
                });

                alert("휴대전화번호로 비밀번호가 발송되었습니다");
            }
        },
    },
};
</script>
