<template>
    <v-btn v-bind:id="'naver-login-button'" v-bind:href="`https://nid.naver.com/oauth2.0/authorize?client_id=${client_id}&response_type=code&redirect_uri=${redirect_uri}`" block color="#00C73C" class="v-size--xx-large">
        <i class="icon icon-naver mr-auto" /><span class="mr-auto white--text"><slot default>네이버 계정으로 로그인</slot></span>
    </v-btn>
</template>

<script>
export default {
    props: {
        client_id: { type: String, default: "" },
    },
    computed: {
        redirect_uri() {
            return `${window.location.protocol}//${window.location.hostname}/oauth/naver`;
        },
    },
};
</script>
