var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('page-section', {
    staticClass: "page-section--first page-section--last",
    attrs: {
      "titAlign": "text-center"
    }
  }, [_c('tit-wrap-dot', {
    attrs: {
      "title": "비밀번호 찾기",
      "alignCenter": ""
    },
    scopedSlots: _vm._u([{
      key: "txtAdd",
      fn: function () {
        return [_vm._v(" 회원가입 시 등록한 휴대폰 번호를 입력해주세요. "), _c('br'), _vm._v(" 등록된 휴대폰 번호로 임시 비밀번호를 전달드립니다. ")];
      },
      proxy: true
    }])
  }), _c('div', {
    staticClass: "mw-580px mx-auto"
  }, [_c('v-row', {
    staticClass: "row--sm ma-md-n6px"
  }, [_c('v-col', {
    staticClass: "pa-md-6px",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "dense": false,
      "placeholder": "아이디를 입력하세요"
    },
    model: {
      value: _vm.username,
      callback: function ($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    staticClass: "pa-md-6px",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    staticClass: "row--xs"
  }, [_c('v-col', [_c('v-text-field', _vm._b({
    attrs: {
      "dense": false,
      "placeholder": "연락처 (‘-’를 제외하고 입력하세요.)"
    },
    model: {
      value: _vm.phone,
      callback: function ($$v) {
        _vm.phone = $$v;
      },
      expression: "phone"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "w-100 min-w-sm-100px",
    attrs: {
      "color": "grey",
      "x-large": ""
    },
    on: {
      "click": _vm.certify
    }
  }, [_vm._v("인증요청")])], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-md-6px",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', _vm._b({
    attrs: {
      "dense": false,
      "type": "number",
      "placeholder": "인증번호를 입력하세요."
    },
    model: {
      value: _vm.otp,
      callback: function ($$v) {
        _vm.otp = $$v;
      },
      expression: "otp"
    }
  }, 'v-text-field', Object.assign({}, _vm.attrs_input, {
    loading: _vm.loading
  }), false))], 1)], 1), _c('div', {
    staticClass: "pt-16px pt-md-20px"
  }, [_c('v-row', {
    staticClass: "row--sm ma-md-n6px"
  }, [_c('v-col', {
    staticClass: "pa-md-6px",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-btn', _vm._b({
    staticClass: "v-size--xx-large",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.recovery
    }
  }, 'v-btn', Object.assign({}, _vm.btn_primary, {
    loading: _vm.loading
  }), false), [_vm._v("비밀번호 찾기")])], 1), _c('v-col', {
    staticClass: "pa-md-6px",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider-group text-center"
  }, [_c('ul', {
    staticClass: "grey--text"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/login"
    }
  }, [_vm._v("로그인")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/login/find-id/"
    }
  }, [_vm._v("아이디 찾기")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/join"
    }
  }, [_vm._v("회원가입")])], 1)])])])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }