<template>
    <v-btn v-bind:id="'kakao-login-btn'" v-bind:href="`https://kauth.kakao.com/oauth/authorize?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code`" block color="#FFEB00" class="v-size--xx-large">
        <i class="icon icon-kakao mr-auto" /><span class="mr-auto"><slot default>카카오톡으로 계속하기</slot></span>
    </v-btn>
</template>

<script>
export default {
    props: {
        client_id: { type: String, default: "" },
    },
    computed: {
        redirect_uri: function() {
            return `${document.location.protocol}//${document.location.hostname}/oauth/kakao`;
        },
    },
};
</script>
